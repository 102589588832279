import { $, $all } from "@olmokit/dom";
import {
  glide,
  Anchors,
  Autoplay,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";

import gsap from "gsap";
import "./index.scss";

/**
 * Component: Slider
 *
 * The component will be initialised simply by calling the function
 * `Slider();` when you need.
 */
export function Slider() {
  console.log("Slider mounted.");

  const glideOptions = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 0,
    autoplay: 6000,
    hoverpause: false,
    gap: 0,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
  };
  
  const element = document.getElementsByClassName("Slider:slider");
  if (element.length > 0) {
    const slider = glide(element[0], glideOptions);
    slider.mount({
      Swipe,
      LazyLoad,
      Anchors,
      Controls,
      Autoplay,

    });
  }

  /**
   * Public API
   *
   * A public API is required only if the component needs to be controlled from
   * outside, that often happens if the component is used asynchronously and it
   * is required if used in a route managed by barba.js.
   */
  return {
    /**
     * Destroy
     *
     * If the component is used asynchronously, especially in a route managed
     * by barba.js you should implement a destroy method where you deregister
     * listeners and eventually stop background js execution of this component.
     * Otherwise you are free to delete this method.
     */
    destroy() {},
  };
}

/**
 * Initialise
 *
 * You can auto initialise the component by simply uncomment the following line.
 * This is the typical usecase of a component used in a "normal" route (not
 * managed by barba.js) as you can be sure that in this scenario the DOM is
 * ready.
 */
// auto init:
// Slider();

// auto init and exports singleton:
// const slider = Slider();
// export default slider;


