import { $, on } from "@olmokit/dom";
import threshold from "@olmokit/core/scroll/threshold";
import { Selectlang } from "components/Selectlang";
import scrollLock, { fillGapsOf } from "@olmokit/core/scroll/lock";
import gsap from "gsap";
import "components/Hamburger";
import "./index.scss";
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";
// import isMobile from "ismobilejs";

/**
 * Component: Header
 *
 */
 export function Header() {
  const hamburger = $(".Hamburger:");
  const collapse = $(".Header:collapse");
  const header = $(".Header:");
  const topheader = $(".Header:nav_top");
  const container = $(".Header:collapse_container");
  const btnStore = $("#HeaderStore");

  Selectlang();

  new SimpleBar(container, { autoHide: true });

  fillGapsOf([header, collapse]);

  on(hamburger, "click", () => {
    if (hamburger.classList.contains("is-active")) {
      scrollLock.enable(document.body);
      hamburger.classList.remove("is-active");
      collapse.classList.remove("is-in");
      header.classList.remove("open");
      gsap.to(topheader, { duration: 1, opacity: 0 });
      gsap.to(btnStore, { duration: 0.2, opacity: 1 });

    } else { 
      scrollLock.disable(document.body);
      hamburger.classList.add("is-active");
      collapse.classList.add("is-in");
      header.classList.add("open");
      gsap.to(topheader, { duration: 1, opacity: 1 });
      gsap.to(btnStore, { duration: 0.2, opacity: 0 });

    }
  });

  threshold(20, { target: header });
}

