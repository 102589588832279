/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "layouts/main";
import "components/Intro";
import "components/MainContent";
import "components/Properties";
import { Slider } from "components/Slider";
import { FormContact } from "components/FormContact";
// import "components/Blog";
import "components/CtaBtn";
import {
    glide,
    Anchors,
    Autoplay,
    Controls,
    LazyLoad,
    Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import "./index.scss";

// FormContact();
Slider();

const glideOptions = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 0,
    autoplay: 6000,
    hoverpause: false,
    gap: 0,
    loop: true,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
};

const element = document.getElementsByClassName("home:productsSlider");
if (element.length > 0) {
    const slider = glide(element[0], glideOptions);
    slider.mount({
        Swipe,
        LazyLoad,
        Anchors,
        Controls,
        Autoplay,
    });
}

console.log("Route home/index.js mounted.");
